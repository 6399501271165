<!----- pozadinska animacija ----->
<div class="area" >
  <ul class="circles">
     <li class="a"></li>
     <li class="a"></li>
     <li class="a"></li>
     <li class="a"></li>
     <li class="a"></li>
     <li class="a"></li>
     <li class="a"></li>
     <li class="a"></li>
     <li class="a"></li>
     <li class="a"></li>
  </ul>
</div >

<div class="firma">
  <span>{{ nazivFirme }}</span>
</div>

<div class="korisnik">
  <button type="button" class="btn odjava" (click)="triggerModal(modalData)">{{ korisnik }}</button>
        <ul> 
            <li class="a">
                <a class="odjava" *ngIf="show" (click)="odjava()" >Odjava</a>
            </li>
        </ul>    
</div>

<div class="container">
  <!-- <p><button style="background-color: red; color: #FFF;" (click)="triggerEuro(modalEuro)">Obavijest</button></p> -->
  <p class="ns_moduli" >Moduli</p>
  <ul class="listaModuli">
    <li *ngFor="let data of moduli">
        <!-- <button class="btn btn-info"></button> -->     
        <div class="data" (click)="odiNaModul(data)" id="moduli">   
          <i id="icon" class="{{data.icon}}" aria-hidden="true"></i>
           <span>{{data.naziv}}</span> 
           <div *ngIf="data.nefisk" class="mesgcircle" title="Broj nefiskaliziranih računa!">{{data.nefisk}}</div>
           <div *ngIf="data.e_racun" class="mesgcircle" title="Broj neuspješnih e-računa!">{{data.e_racun}}</div>
           <div *ngIf="data.predistek" class="mesgcircle" title="Broj nefakturiranih ugovora!">{{data.predistek}}</div>
        </div>
    </li>
  </ul>
</div>

<!----- MODAL ZA ODJAVU ------>
<ng-template #modalData let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Odjava</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()" >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">    
    <p>Jeste li sigurni da se želite odjaviti iz aplikacije?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close()">Odjava</button>
  </div>
</ng-template>

<!-- --- MODAL ZA EURO ---- -->
<!-- <ng-template #modalEuro let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-euro">Obavijest</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()" >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">    
    <p>Poštovani korisnici,</p>
    <p>Obavještavamo Vas kako dana 21.4.24. (nedjelja) u periodu od 16:00 do 18:00 sati FORTIS neće biti dostupan zbog planiranih radova i ažuriranja na sustavu. Za sve dodatne informacije i upite kontaktirajte nas na: <a href="mailto:tehnicka.podrska@fortuno.hr">tehnicka.podrska@fortuno.hr</a> ili nazovite broj: +385 91 606 1272</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.close()">Zatvori obavijest</button>
  </div>
</ng-template> -->

<div *ngIf="loader" class="circles">
  <div class="loader"></div>
</div>

