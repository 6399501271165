import { Injectable } from '@angular/core';

@Injectable()
 
export class AppConfig {

        /** ----------------------- MODULI NA LABU-----------------------  */
// public static ipAddressPrijava =    "https://lab.fortuno.hr:3003/";            // PRIJAVA
// public static ipAddressPonude =     "https://lab.fortuno.hr:7097/";            // PONUDE I RAČUNI
// public static ipAddressUgovori =    "https://lab.fortuno.hr:7091/";            // UGOVORI
// public static ipAddressSkladisno =  "https://lab.fortuno.hr:7101/";            // SKLADIŠNO POSLOVANJE
// public static ipAddressKnjizenje =  "https://lab.fortuno.hr:7104/";            // KNJIŽENJE
// public static ipAddressERacuni =    "https://lab.fortuno.hr:7105/";            // E-RAČUN
// public static ipAddressIzvjestaji = "https://lab.fortuno.hr:7106/";            // IZVJEŠTAJI
// public static ipAddressKadrovi =    "https://lab.fortuno.hr:7108/";            // KADROVI
// public static ipAddressLicence =    "https://lab.fortuno.hr:7109/";            // LICENCE - ADMINISTRACIJA KORISNIKA
// public static ipAddressCrm =        "https://lab.fortuno.hr:7117/";            // CRM
// public static ipAddressGodisnji =   "https://lab.fortuno.hr:7120/";            // GODISNJI
// public static ipAddressNalozi =     "https://lab.fortuno.hr:7127/";            // RADNI NALOZI
// public static ipAddressMaloprodaja ="https://lab.fortuno.hr:7132/";            // MALOPRODAJA
// public static ipAdresaNatjecajiZaPosao = "https://lab.fortuno.hr:7131/";       // NATJEČAJI ZA POSAO

      /** ----------------------- MODULI NA PRODUKCIJI ----------------------- */
  public static ipAddressPrijava =    "https://fortis.fortuno.hr:3003/";               // PRIJAVA
  public static ipAddressPonude =     "https://ponudeiracuni.fortuno.hr/";             // PONUDE I RAČUNI
  public static ipAddressUgovori =    "https://ugovori.fortuno.hr/";                   // UGOVORI
  public static ipAddressSkladisno =  "https://skladisnoposlovanje.fortuno.hr/";       // SKLADIŠNO POSLOVANJE
  public static ipAddressKnjizenje =  "https://knjizenje.fortuno.hr/";                 // KNJIŽENJE
  public static ipAddressERacuni =    "https://e-racun.fortuno.hr/";                   // E-RAČUN
  public static ipAddressIzvjestaji = "https://izvjestaji.fortuno.hr/";                // IZVJEŠTAJI
  public static ipAddressKadrovi =    "https://kadrovi.fortuno.hr/";                   // KADROVI
  public static ipAddressLicence =    "https://licence.fortuno.hr/";                   // LICENCE - ADMINISTRACIJA KORISNIKA
  public static ipAddressCrm =        "https://fortis-crm.fortuno.hr/";                // CRM
  public static ipAddressGodisnji =   "https://godisnji.fortuno.hr/";                  // GODISNJI
  public static ipAddressNalozi =     "https://radni-nalozi.fortuno.hr/";              // RADNI NALOZI
  public static ipAddressMaloprodaja ="https://maloprodaja.fortuno.hr/";               // MALOPRODAJA
  public static ipAdresaNatjecajiZaPosao = "https://natjecaji.fortuno.hr/";            // NATJEČAJI ZA POSAO

}